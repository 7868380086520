















import { Vue, Component, Watch } from 'vue-property-decorator';
import Page from '@/layouts/Page.vue';
import BackButtonLayout from '@/layouts/management/BackButtonLayout.vue';
import HomeworkFormTemplate from '@/components/homework/HomeworkFormTemplate.vue';
import { CreateHomeworkItemForAxios, GeneralHomeworkItem } from '@/models/homework';
import { DropdownItem } from '@/models/dropdown';
import { CourseStores } from '@/store/course';
import { HomeworkStores } from '@/store/homeworks';
import moment from 'moment';
import { convertDateToDateAnd12HourFormat } from '@/utils/moment';
@Component({
    components: { Page, BackButtonLayout, HomeworkFormTemplate },
})
export default class CreateHomework extends Vue {

    currentCourses: DropdownItem[] = [];
    courseListStore = CourseStores.list;
    homeworkStateStore = HomeworkStores.state;
    homeworkCreateStore = HomeworkStores.create;
    coursePrefixId = 0;
    courseStartDate: Date | null = null;
    courseEndDate: Date | null = null;

    get layoutTitle() {
        return `Add New Homework`;
    }

    get courses() {
        return this.currentCourses;
    }

    mounted() {
        this.courseListStore.retrieveAllSiteCoursesWithParams({});
        if (this.homeworkStateStore.homeworkFolderState.length > 0) {
            this.coursePrefixId = this.homeworkStateStore.homeworkFolderState[this.homeworkStateStore.homeworkFolderState.length - 1].id;
        }
    }

    getDateRange(courseId: number) {
        const courseIndex = this.courseListStore.response!.objects.findIndex((data) => {
            return data.id === courseId;
        });
        if (courseIndex > -1) {
            const data = this.courseListStore.response!.objects[courseIndex];
            this.courseStartDate = moment(data.startDate).toDate();
            this.courseEndDate = moment(data.startDate).add(data.duration.months , `months`).add(data.duration.weeks, `weeks`).toDate();
        }
    }

    @Watch(`courseListStore.response`)
    parseCourseData() {
        if (this.courseListStore.response) {
            this.currentCourses = [];
            this.courseListStore.response.objects.forEach((data) => {
                const endDate = moment(data.startDate).add(data.duration.months , `months`).add(data.duration.weeks, `weeks`).toDate();
                this.currentCourses.push({
                    text: `${data.courseName} ( ${ convertDateToDateAnd12HourFormat(data.startDate) } - ${convertDateToDateAnd12HourFormat(endDate)} )`,
                    value: data.id.toString(),
                    uniqueKey: `data_${data.courseName}`
                });
            });
        }
    }

    createNewHomework(data: GeneralHomeworkItem) {
        const payload: CreateHomeworkItemForAxios = {
            ...data,
            startDate: moment(data.startDate).format(`YYYY-MM-DD`),
            deadline: moment(data.deadline).format(`YYYY-MM-DD`),
            availability: data.availability ? 1 : 0,
            description: `No description`,
            tuitionId: 1
        };
        this.homeworkCreateStore.createHomework(payload);
    }

    @Watch(`homeworkCreateStore.response`)
    redirectToHomeworkList() {
        if (this.homeworkCreateStore.response) {
            this.homeworkStateStore.updateHomeworkIsModified(true);
            this.$router.push(
                {
                    path: `/homeworks`,
                }
            );
        }
    }

}
